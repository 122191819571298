.connect-button button {
    min-width: 130px;
    height: 40px;
    display: grid;
    place-items: center;
    background-color: #E74625 !important;
    font-weight: 500 !important;
  }

  .secondary button {
    background: #FFFEFD !important;
    color: #E74625 !important;
  }