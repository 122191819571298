.links-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
  height: 100%;
  flex-wrap: wrap;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 10%;
  height: 100%;
}

@media screen and (max-width: 600px) {
  .links-container {
    width: 100%;
  }
  .icons-container {
    width: 100%;
    margin-bottom: 10px;
  }
}
